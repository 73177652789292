/* App.css or ImagePage.css */

.image-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    position: relative;
    background-color: #f0f2f5; /* Light background for better contrast */
  }
  
  .logo {
    position: absolute;
    top: 20px;
    left: 20px;
    width: 100px;
  }
  
  .image-container {
    margin: 20px 0;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .image-container img {
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Shadow around the image */
  }
  
  .link-container {
    margin-top: 20px;
    padding: 15px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* Shadow around the link box */
    width: 80%;
    max-width: 400px;
  }
  
  button {
    margin-top: 15px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    box-shadow: 0 4px 6px rgba(0, 123, 255, 0.4); /* Button shadow */
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  
  button:hover {
    background-color: #0056b3;
    box-shadow: 0 6px 12px rgba(0, 123, 255, 0.6); /* More intense shadow on hover */
  }
  
  a {
    color: #007bff;
    text-decoration: none;
    font-weight: bold;
  }
  
  a:hover {
    text-decoration: underline;
  }
  