/* src/App.css */

.App {
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #ffffff;
    color: #3E4E5E;
    min-height: 100vh;
    padding: 20px;
  }
  
  .App-header {
    margin-bottom: 40px;
  }
  
  .App-logo {
    width: 200px;
    height: 200px;
    margin-bottom: 20px;
  }
  
  h1 {
    font-size: 2.5rem;
  }
  
  .dropzone {
    border: 2px dashed #3498DB;
    border-radius: 10px;
    padding: 40px;
    cursor: pointer;
    margin: 0 auto 20px;
    max-width: 500px;
  }
  
  .dropzone.drag-over {
    background-color: #f0f8ff;
  }
  
  .dropzone p {
    font-size: 1.2rem;
    color: #7f8c8d;
  }
  
  .upload-button {
    background-color: #3498DB;
    color: #ffffff;
    border: none;
    padding: 15px 30px;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .upload-button:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  
  .upload-button:hover:not(:disabled) {
    background-color: #2980B9;
  }